import * as React from "react";
import { RootState } from "~/state";
import { Action, Dispatch } from "redux";
import { connect } from "react-redux";
import {
    getDepartments,
    getUserInformation,
    getOperators,
    getCalendarOperator
} from "../../state/action";
import {
    CommandBar,
    ICommandBarItemProps,
    IContextualMenuItem,
    Checkbox
} from "@fluentui/react";
import { getDepartmentContextualMenuItemOptions } from "../utils/departmentHelper";
import CreateEventDialog from "./CreateEventDialog";
import { findUserDepartment } from "./util/departmentHelper";
import * as uniqId from "uniqid";
import CalendarWrapper from "./CalendarWrapper";
import DefaultEventDialog from "./DefaultEventDialog";
import BookingDialog from "./BookingDialog";
import { updateOperators } from "../../state/user/action";
import { getMenuItemsForOperators } from "../utils/operatorsHelper";
import { setCalendarOperator } from "../../state/ui/action";
import ReactSelectDropdown from "./util/ReactSelectDropdown";
import Department from "../departments";

const CalendarContainer: React.FunctionComponent<StateProps> = props => {
    const [departmentsMenuOptions, setDepartmentsMenuOptions] = React.useState<
        IContextualMenuItem[]
    >([]);
    const [selectedDepartments, setSelectedDepartments] = React.useState<
        { id: string; updateId: string }[]
    >([]);
    const [filters, setFilters] = React.useState<string[]>([
        "DEPARTMENT",
        "TIMESLOT",
        "WINDSCREEN",
        "STONECHIP",
        "SALE",
        "COMPLAINT"
    ]);
    const [createEvent, setCreateEvent] = React.useState<boolean>(false);
    const [show24hours, setShow24hours] = React.useState<boolean>(false);

    const [selectedOperator, setSelectedOperator] = React.useState<{
        value: string;
        label: string;
    } | null>(null);

    React.useEffect(() => {
        if (props.operators.length === 0) {
            props.updateOperators();
        }
    }, []);

    React.useEffect(() => {
        if (props.departments) {
            const menu = getDepartmentContextualMenuItemOptions(
                props.departments.filter(
                    x =>
                        x.parentDepartmentId === undefined ||
                        x.parentDepartmentId === null
                )
            );
            setDepartmentsMenuOptions(menu);
            if (
                props.user.departmentGuid !==
                "00000000-0000-0000-0000-000000000000"
            ) {
                const userDepartment = findUserDepartment(
                    props.user,
                    props.departments
                );
                if (userDepartment) {
                    setSelectedDepartments([
                        { id: userDepartment.id as string, updateId: uniqId() }
                    ]);
                }
            }
        }
    }, [props.departments, props.user]);

    const onDepartmentClick = React.useCallback(
        (departmentIndex: number, departmentId: string) => {
            const temp = [...selectedDepartments];
            temp[departmentIndex] = {
                id: departmentId,
                updateId: uniqId()
            };
            setSelectedDepartments(temp);
            setSelectedOperator(null);
        },
        [selectedDepartments]
    );

    const createNewEventToggleClick = React.useCallback(
        () => setCreateEvent(!createEvent),
        [createEvent]
    );

    const onSaveEventClick = React.useCallback(
        (departmentId: string) => {
            const index = selectedDepartments.findIndex(
                x => x.id === departmentId
            );
            if (index !== undefined) {
                const temp = [...selectedDepartments];
                temp[index] = {
                    ...selectedDepartments[index],
                    updateId: uniqId()
                };
                setSelectedDepartments(temp);
            }
            setCreateEvent(false);
        },
        [selectedDepartments]
    );

    const onFilterChange = React.useCallback(
        (value: string) => () => {
            if (filters.indexOf(value) > -1) {
                setFilters(filters.filter(x => x !== value));
            } else {
                setFilters([...filters, value]);
            }
        },
        [filters]
    );

    const checkBoxMenuItemRender = React.useCallback(
        (key: string, text: string): ICommandBarItemProps => {
            return {
                key,
                split: true,
                onRender: () => (
                    <div className="checkobx_wrapper">
                        <Checkbox
                            onChange={onFilterChange(key)}
                            checked={filters.indexOf(key) > -1}
                            label={text}
                        />
                    </div>
                )
            };
        },
        [filters]
    );

    const departmentMenuItemRender = (key: number) => {
        const selectedDepartment = selectedDepartments[key];
        const selectedDepartmentId = selectedDepartment
            ? selectedDepartment.id
            : "";

        const menuTitle = selectedDepartment
            ? departmentsMenuOptions.find(
                  option => option.key === selectedDepartmentId
              )
            : undefined;

        const menuTitleText = menuTitle ? menuTitle.text : "Vælg en afdeling";

        const departmentOptions = departmentsMenuOptions.map(option => ({
            value: option.key,
            label: option.text
        }));

        const handleChange = (
            selectedOption: { value: string; label: string } | null
        ) => {
            if (selectedOption) {
                onDepartmentClick(key, selectedOption.value);
            }
        };

        if (selectedDepartmentId) {
            departmentOptions.unshift({ value: "", label: "Slet valgte" });
        }

        return (
            <div className="select-department" key={"department" + key}>
                <ReactSelectDropdown
                    options={departmentOptions}
                    isSearchable={true}
                    value={selectedDepartment}
                    label={menuTitleText || ""}
                    onChange={handleChange}
                    isDisabled={!(key <= selectedDepartments.length)}
                    placeholder={""}
                />
            </div>
        );
    };

    const onToggleShow24hours = () => setShow24hours(!show24hours);

    const onOperatorClick = React.useCallback(
        (selectedOperator: { value: string; label: string } | null) => {
            if (selectedOperator) {
                setSelectedOperator(selectedOperator);
                props.setCalendarOperator(selectedOperator.value);
            }
        },
        [props.setCalendarOperator]
    );

    const operatorsMenuItemRender = (
        selectedOperator: { value: string; label: string } | null,
        operators: UserInformation[],
        departments: Department[],
        selectedDepartments: { id: string; updateId: string }[],
        onOperatorChange: (
            selectedOperator: { value: string; label: string } | null
        ) => void
    ) => {
        const departmentIds = selectedDepartments.flatMap(department => {
            const dept = departments.find(d => d.id === department.id);
            return dept ? [dept.id, dept.navLocationCode] : [];
        });

        const operatorsForSelectedDepartments = operators.filter(
            x =>
                (x.calendarDepartmentId &&
                    departmentIds.indexOf(x.calendarDepartmentId) > -1) ||
                (x.departmentGuid !== "00000000-0000-0000-0000-000000000000" &&
                    departmentIds.indexOf(x.departmentGuid) > -1)
        );

        const operatorsOptions = operatorsForSelectedDepartments.map(
            option => ({
                value: option.name,
                label: option.name
            })
        );

        const menuTitleText = selectedOperator
            ? selectedOperator.label
            : "Vælg en montør";

        return (
            <div className="select-department" key={"operator" + menuTitleText}>
                <ReactSelectDropdown
                    options={operatorsOptions}
                    isSearchable={true}
                    value={selectedOperator ? selectedOperator.value : ""}
                    label={menuTitleText || ""}
                    onChange={onOperatorChange}
                    isDisabled={false}
                    placeholder={""}
                />
            </div>
        );
    };

    const _items: ICommandBarItemProps[] = [
        {
            key: "newItem",
            text: "Ny Aftale",
            iconProps: { iconName: "Add" },
            split: true,
            onClick: createNewEventToggleClick
        },
        checkBoxMenuItemRender("DEPARTMENT", "Afdeling"),
        checkBoxMenuItemRender("TIMESLOT", "Tidslots"),
        checkBoxMenuItemRender("WINDSCREEN", "Rudeskift"),
        checkBoxMenuItemRender("STONECHIP", "Sten slag"),
        checkBoxMenuItemRender("SALE", "Mersalg"),
        checkBoxMenuItemRender("COMPLAINT", "Reklamation"),
        {
            key: "24:00",
            split: true,
            onRender: () => (
                <div className="checkobx_wrapper">
                    <Checkbox
                        onChange={onToggleShow24hours}
                        checked={show24hours}
                        label={"24:00"}
                    />
                </div>
            )
        }
    ];

    const clearSelected: ICommandBarItemProps = {
        key: "clear",
        text: "Clear",
        split: true,
        disabled: selectedDepartments.length <= 1,
        iconProps: { iconName: "Clear" },
        onClick: () => {
            setSelectedDepartments([selectedDepartments[0]]);
        }
    };

    const refresh: ICommandBarItemProps = {
        key: "refresh",
        text: "Genindlæs",
        split: true,
        iconProps: { iconName: "Refresh" },
        onClick: () => {
            const update = selectedDepartments.map(x => ({
                ...x,
                updateId: uniqId()
            }));
            setSelectedDepartments(update);
        }
    };

    const toggleCanceledOrders: ICommandBarItemProps = checkBoxMenuItemRender(
        "CANCELED",
        "Annullerede ordrer"
    );

    return (
        <>
            <div className="command_bar command_bar_calendar">
                <CommandBar
                    items={_items}
                    farItems={[toggleCanceledOrders, refresh, clearSelected]}
                />
                 <div className="select-departments">
                {operatorsMenuItemRender(
                    selectedOperator,
                    props.operators,
                    props.departments,
                    selectedDepartments,
                    onOperatorClick
                )}
               
                {departmentMenuItemRender(0)}
                {departmentMenuItemRender(1)}
                {departmentMenuItemRender(2)}
                </div>
            </div>
            {createEvent ? (
                <CreateEventDialog
                    onSave={onSaveEventClick}
                    user={props.user}
                    selectedDepartment={selectedDepartments[0]}
                    departments={props.departments}
                    close={createNewEventToggleClick}
                />
            ) : null}
            <CalendarWrapper
                show24hours={show24hours}
                allDepartments={props.departments}
                departments={selectedDepartments}
                filters={filters}
                operator={props.calendarOperator}
            />
            <DefaultEventDialog onSave={onSaveEventClick} />
            <BookingDialog onSave={onSaveEventClick} />
        </>
    );
};

const mapStateToProps = (state: RootState) => {
    return {
        operators: getOperators(state),
        departments: getDepartments(state),
        user: getUserInformation(state),
        calendarOperator: getCalendarOperator(state)
    };
};

const mapToDispatchProps = (dispatch: Dispatch<Action>) => ({
    updateOperators: () => dispatch<any>(updateOperators()),
    setCalendarOperator: (operator: string) =>
        dispatch<any>(setCalendarOperator(operator))
});

type StateProps = ReturnType<typeof mapStateToProps> &
    ReturnType<typeof mapToDispatchProps>;

export default connect(mapStateToProps, mapToDispatchProps)(CalendarContainer);
